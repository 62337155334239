<template>
  <v-content app>
    <portalMobile />
  </v-content>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["app"])
  },
  components: {
    portalMobile: () => import("./portalMobile.vue"),
  }
};
</script>

<style>
/* menu  */
#menuFooter .v-btn:before {
  background-color: transparent;
}

/* badge size */
#menuFooter .v-badge__badge {
  height: 14px !important;
  width: 14px !important;
  min-width: 14px !important;
}
#menuFooter .v-badge__badge span {
  font-size: 12px !important;
}
#menuFooter .v-badge--overlap .v-badge__badge {
  top: -6px !important;
  right: -6px !important;
}

/*  */
.hTerco {
  height: 33.3333333%;
}
.hQuarto {
  height: 25%;
}

/*  */
.roundedItem {
  border-radius: 5px;
}
.thinbr > br {
  display: block;
  content: "";
  margin-top: -12px;
}

/*  */
div.container{
  background-color: transparent;
}
.v-input .v-icon {
  font-size: 15px !important;
}
</style>
